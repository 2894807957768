import './css/style.css'
// import { p8_run_cart } from './js/pico8'

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as dat from 'dat.gui'
// import Stats from 'stats.js'
// import * as Perlin from './perlin.js'
// import oceanVertexShader from './shaders/ocean/vertex.glsl'
// import oceanFragmentShader from './shaders/ocean/fragment.glsl'
import { gsap } from 'gsap'
import { IcosahedronGeometry } from 'three'
// import { IGLUniformData } from 'pixi.js'
// import { mainDialog } from './data/main_dialog'
const mainDialog = require('./data/main_dialog.json');
// const stats = new Stats()
// stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom

// document.body.appendChild(stats.dom)

/**
 * Base
 */
// Debug
// const gui = new dat.GUI({ width: 340 })
// const debugObject = {}
let sceneReady = false
let dialogEnabled = false
let dialogInfos = {}
var bFirstVisit


const a = document.createElement("a");
if (a.relList.supports("ar")) {
    // AR is available.
}
else {
    document.querySelector("a.arkit").remove()
}

const bookMeeting = () => {
    Calendly.initPopupWidget({ url: 'https://calendly.com/papanouel/30min-call' });
}

const playGame = () => {

    focusOnCamera(cameraArcade, "play_game", launch)
}

function launch() {
    // load pico8 library
    // PicoPlayer('pico-container', './images/omega_enforcer_x.p8.png');
    // PicoPlayer('pico-container', picoCart);


    // require.ensure([], function () {
    // let config = require('./js/shooter.js');
    // p8_run_cart(pico8game);
    // });

    if (!arcadeScreenTexture) {
        arcadeScreenTexture = new THREE.CanvasTexture(Module.canvas);
        // arcadeScreenTexture = new THREE.CanvasTexture(document.querySelector("canvas.webgl-pixi"));
        arcadeScreenTexture.minFilter = THREE.NearestFilter;
        arcadeScreenTexture.magFilter = THREE.NearestFilter;
        arcadeScreenTexture.encoding = THREE.sRGBEncoding;

        if (arcadeScreenMaterial)
            arcadeScreenMaterial.dispose();
        arcadeScreenMaterial = new THREE.MeshBasicMaterial({ color: 0x88ffe5, map: arcadeScreenTexture, side: THREE.DoubleSide })
        arcadeScreenMesh.material = arcadeScreenMaterial;
    }
}

const startGame = () => {

}

const watchPainting = () => {

    focusOnCamera(cameraPainting, "watch_painting")
}

const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
const checkFirstVisit = () => {

    bFirstVisit = true;
    let first = getCookie("firstVisit");

    if (first == "false")
        bFirstVisit = false;
    else
        setCookie("firstVisit", "false", 365);

    return bFirstVisit;
}

const focusOnCamera = (cameraObj = cameraCharacter, dialogID = "main", callback = null) => {

    dialogEnabled = true;
    disableInteractions();

    let startOrientation = camera.quaternion.clone()

    const targetOrientation = new THREE.Quaternion()

    targetOrientation.setFromRotationMatrix(cameraObj.matrixWorld)
    // const targetOrientation = cameraObj.parent.quaternion.clone();
    // targetOrientation.multiply(cameraObj.quaternion)

    gsap.to(camera.position, {
        ease: "power1.out", duration: 1.2, x: cameraObj.parent.position.x, y: cameraObj.parent.position.y, z: cameraObj.parent.position.z, onUpdate: function () {

        }, onComplete: function () {

            if (callback != null) {
                callback();
            }
            startDialog(dialogID)
        }
    })

    gsap.to({}, {
        duration: 1.2, onUpdate: function () {
            camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
        }, onComplete: function () {


        }
    })

}

const focusOnAll = () => {

    let startOrientation = camera.quaternion.clone()

    gsap.to(camera.position, {
        ease: "power1.inOut", duration: 1.2, x: cameraMain.position.x, y: cameraMain.position.y, z: cameraMain.position.z, onUpdate: function () {



        }, onComplete: function () {
            dialogEnabled = false;
            enableInteractions()
        }
    })

    gsap.to({}, {
        duration: 0.8, onUpdate: function () {
            camera.quaternion.copy(startOrientation).slerp(cameraMain.quaternion, this.progress());
        }, onComplete: function () {

        }
    })
}

// Colors
// debugObject.depthColor = '#2aa9f0'
// debugObject.surfaceColor = '#9bd8ff'

// Canvas
const canvas = document.querySelector('canvas.webgl')
const dialogBox = document.querySelector('div.dialog-box')
const dialogBoxContent = document.querySelector('div.dialog-box-content')

const dialogBoxButtons = document.querySelector('div.dialog-box-buttons')

const dialogBoxMaxWidth = dialogBox.style.maxWidth
const dialogBoxContentMargin = dialogBoxContent.style.margin

const loadingBarElement = document.querySelector('.loading-bar')
const actionBarElement = document.querySelector('.ui-action-bar')
const raycaster = new THREE.Raycaster()


const points = [
    {
        name: 'papanouel',
        position: new THREE.Vector3(-0.02, 1.6, -1.21),
        actionFunc: focusOnCamera
    },
    {
        name: 'agenda',
        position: new THREE.Vector3(-0.71, 0.85, -0.79),
        actionFunc: bookMeeting
    },
    {
        name: 'unitedfrogs',
        position: new THREE.Vector3(1.86, 2.4005, -1.34),
        actionFunc: watchPainting
    }/*
    {
        name: 'arcade',
        position: new THREE.Vector3(1.3, 2.0, 1.6),
        actionFunc: playGame
    }
    */


]

// const pixiCanvas = document.createElement('canvas');
var pixiCanvas;
// pixiCanvas.width = 256;
// pixiCanvas.height = 256;


/*
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
PIXI.settings.ROUND_PIXELS = true;
const pixiRenderer = new PIXI.Renderer(
    {
        view: pixiCanvas,
        width: pixiCanvas.width,
        height: pixiCanvas.height,
        resolution: 1
    }
);

const pixiLoader = new PIXI.Loader();
pixiLoader.add('player', playerFilename);

const pixiStage = new PIXI.Container();

var startText, startTextTime = 0

pixiLoader.load((loader, resources) => {

    console.log(resources)

    // const playerTexture = new PIXI.Texture.from(pixiLoader.resources.player);
    // const sprite = new PIXI.Sprite(resources.player.texture);
    // sprite.x = pixiCanvas.width / 2;
    // sprite.y = pixiCanvas.height / 2;
    // sprite.width = 64;
    // sprite.height = 64;
    // sprite.anchor.x = 0.5
    // sprite.anchor.y = 0.5
    // pixiStage.addChild(sprite);


    startText = new PIXI.Text("PRESS START", { fontFamily: 'Press Start 2P', fontSize: 16, fill: 'white' }
    );

    startText.x = pixiCanvas.width / 2;
    startText.y = pixiCanvas.height - 64;
    startText.anchor.x = 0.5
    startText.anchor.y = 0.0

    pixiStage.addChild(startText);
});


const ticker = new PIXI.Ticker();
ticker.add(() => {

    if (startText) {
        startTextTime += 0.01;

        if (startTextTime >= 0.5) {
            startTextTime = 0
            startText.visible = !startText.visible
            arcadeScreenTexture.needsUpdate = true
        }
    }

    pixiRenderer.render(pixiStage)

})

ticker.start();
*/

const exitDialog = () => {

    hideButtons()

    focusOnAll()
    showDialog(false)
}

function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
};

window.addEventListener('message', function (e) {



    if (isCalendlyEvent(e)) {
        // console.log(e.data);
    }
});

const startDialog = (dialogID) => {

    dialogInfos.dialogID = dialogID
    dialogInfos.msgIndex = 0
    dialogInfos.message = mainDialog.dialog[dialogInfos.dialogID].message

    createDialogMessage()
}

const setupDialogMessage = () => {

    dialogInfos.blockIndex = 0
    dialogInfos.charIndex = 0
    dialogInfos.textLength = []
    dialogInfos.block = dialogInfos.message[dialogInfos.msgIndex].block
    dialogInfos.spanList = []

    dialogBox.classList.add("disabled")
    dialogBox.style.maxWidth = dialogBoxMaxWidth
    dialogBoxContent.innerHTML = ""
    dialogBoxContent.style.margin = dialogBoxContentMargin
    dialogBoxButtons.innerHTML = ""

}

const createDialogMessage = () => {

    setupDialogMessage()

    let textLength = 0

    try {
        for (let i = 0; i < dialogInfos.block.length; i++) {

            let text = dialogInfos.block[i].text

            if (text.search("<iframe") != -1) {
                dialogBox.style.maxWidth = "none"
                dialogBoxContent.style.margin = "16px 0px 40px 0px"
                dialogBoxContent.innerHTML = text
                dialogInfos.textLength[i] = 0
                continue
            }

            textLength += text.length
            dialogInfos.textLength[i] = textLength

            let elLine = document.createElement("span")

            if (dialogInfos.block[i].hasOwnProperty('newline') && dialogInfos.block[i].newline) {
                elLine.style.display = "block"
            }

            if (dialogInfos.block[i].hasOwnProperty('button') && dialogInfos.block[i].button.hasOwnProperty('cmd')) {

                if (dialogInfos.block[i].button.cmd == "goto") {

                    // elLine.classList.add("selection")

                    elLine.onclick = () => {
                        showDialog(false)
                        window.setTimeout(() => {
                            startDialog(dialogInfos.block[i].button.params[0])
                        }, 500)
                    }
                }
                else if (dialogInfos.block[i].button.cmd == "exit_dialog") {
                    elLine.onclick = () => {
                        exitDialog()
                    }
                }
            }


            text.split('').forEach(element => {

                let el = document.createElement("span")

                if (dialogInfos.block[i].hasOwnProperty('color')) {
                    el.style.color = mainDialog.settings.color_def[dialogInfos.block[i].color]
                    el.style.fontWeight = 600
                }
                else {
                    el.style.color = mainDialog.settings.color_def['default']
                    el.style.fontWeight = 600
                }

                if (dialogInfos.block[i].hasOwnProperty('size')) {
                    el.classList.add("small")
                }

                el.innerHTML = element
                dialogInfos.spanList.push(el)
                elLine.appendChild(el)
            });

            dialogBoxContent.appendChild(elLine)
        }
    }
    catch (e) {

        console.log(e)
    }

    if (dialogInfos.block[0].hasOwnProperty('speed'))
        dialogInfos.speed = mainDialog['settings']['speed_def'][dialogInfos.block[0].speed]
    else
        dialogInfos.speed = mainDialog['settings']['speed_def']['default']


    if (dialogInfos.msgIndex == 0) {
        showDialog(true)
    }
    else
        updateDialog()
}

const showDialog = (show) => {

    if (show) {
        dialogBox.parentElement.classList.add('visible')
        dialogBox.classList.add('visible')
        dialogBox.addEventListener('transitionend', updateDialog)
    }
    else {
        dialogBox.parentElement.classList.remove('visible')
        dialogBox.classList.remove('visible')
        dialogBox.removeEventListener('transitionend', updateDialog)
    }
}

const nextDialogMessage = () => {

    let result = false

    if (dialogInfos.blockIndex == dialogInfos.block.length - 1) {
        //Every text of the message has been displayed

        // if (dialogInfos.lastAction.cmd != "wait_for_selection") {
        dialogBox.removeEventListener('transitionend', updateDialog) //Remove tteh listener on animation

        window.setTimeout(() => {
            showButtons()

            // listenUserInput = true
            dialogBox.classList.remove("disabled")
            dialogInfos.blockIndex++
            dialogInfos.msgIndex++
        }, 500)

        // }


    }
    else {
        let block = dialogInfos.block[dialogInfos.blockIndex]

        if (block.hasOwnProperty('speed'))
            dialogInfos.speed = mainDialog['settings']['speed_def'][block.speed]
        else
            dialogInfos.speed = mainDialog['settings']['speed_def']['default']

        dialogInfos.blockIndex++

        if (block.hasOwnProperty('delay')) {
            window.setTimeout(updateDialog, block['delay'])
        }
        else {
            result = true
        }
    }

    return result
}

const executeCommand = (action) => {

    // console.log(action)

    if (action.cmd == "continue") {
        hideButtons()
        createDialogMessage()
    }
    else if (action.cmd == "exit_dialog") {
        exitDialog()
    }
    else if (action.cmd == "launch_agenda") {
        exitDialog()
        bookMeeting()
    }
    else if (action.cmd == "start_game") {
        exitDialog()
        startGame()
    }
    else if (action.cmd == 'goto' && action.params.length > 0) {
        showDialog(false)
        window.setTimeout(() => {
            startDialog(action.params[0])
        }, 500)
    }
}

const showButtons = () => {


    if (!dialogInfos.message[dialogInfos.msgIndex].hasOwnProperty("buttons"))
        return

    const buttons = dialogInfos.message[dialogInfos.msgIndex].buttons

    let firstBtn = true;

    for (const button of buttons) {

        let txtEl = document.createElement("span")
        txtEl.innerHTML = button.text

        let btnEl = document.createElement("div")
        btnEl.classList.add("dialog-box-btn")
        btnEl.appendChild(txtEl)

        if (button.hasOwnProperty("action")) {
            btnEl.addEventListener('click', (e) => {
                executeCommand(button.action)
            })
        }

        dialogBoxButtons.append(btnEl)
    }

    window.setTimeout(() => {
        dialogBoxButtons.classList.add("appear")
    }, 200)
}

const hideButtons = () => {
    dialogBoxButtons.classList.remove("appear")
}

const updateDialog = () => {

    let update = true

    if (dialogInfos.charIndex == dialogInfos.textLength[dialogInfos.blockIndex]) {
        update = nextDialogMessage()
    }

    if (update) {
        dialogInfos.spanList[dialogInfos.charIndex++].classList.add('visible')
        window.setTimeout(updateDialog, dialogInfos.speed)
    }
}

// for (const point of points) {

//     point.element.childNodes[0].innerHTML = "<img class='icon' src='" + point.imageUrl + "'/>"
// }

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {


        const bodyElement = document.querySelector('body')
        bodyElement.style.background = "rgb(255,255,255)";
        bodyElement.style.background = "-moz-radial-gradient(ellipse, rgba(255,255,255,1) 0%, rgba(28,208,236,1) 95%, rgba(87,186,216,1) 100%)"
        bodyElement.style.background = "-webkit-radial-gradient(ellipse, rgba(255,255,255,1) 0%, rgba(28,208,236,1) 95%, rgba(87,186,216,1) 100%)"
        bodyElement.style.backgroun = "radial-gradient(ellipse, rgba(255,255,255,1) 0%, rgba(28,208,236,1) 95%, rgba(87,186,216,1) 100%)"
        bodyElement.style.filter = "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff'',endColorstr='#57bad8',GradientType=1)";

        window.setTimeout(() => {
            gsap.to(overlayMaterial.uniforms.uAlpha,
                {
                    duration: 1, value: 0, delay: 1,
                    onComplete: function () {
                        sceneReady = true

                        // tidioChatApi.display(false);
                        // actionBarElement.classList.remove('visible');

                        if (window.location.href.indexOf("/#agenda") > -1) {

                            if (checkFirstVisit())
                                focusOnCamera(cameraCharacter, "book_meeting")
                            else {
                                enableInteractions()
                                bookMeeting()
                            }
                        }
                        else {

                            if (checkFirstVisit())
                                focusOnCamera(cameraCharacter, "welcome")
                            else {
                                enableInteractions()
                            }
                        }
                    }
                })

            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''

        }, 500)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) => {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)

// Scene
const scene = new THREE.Scene()

// const axesHelper = new THREE.AxesHelper(5);
// scene.add(axesHelper);

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})

const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

/**
 * Textures
 */

const frogList = ['167', '043', '018', '015', '001', '047']

var frogIndex = frogList[Math.floor(Math.random() * frogList.length)];

const textureLoader = new THREE.TextureLoader(loadingManager)
const bakedTexture = textureLoader.load('/models/baked_v6.jpg')
const paintingTexture = textureLoader.load('/models/frog_' + frogIndex + '.png')
paintingTexture.encoding = THREE.sRGBEncoding
paintingTexture.minFilter = THREE.NearestFilter
paintingTexture.magFilter = THREE.NearestFilter
paintingTexture.flipY = false

bakedTexture.flipY = false
bakedTexture.encoding = THREE.sRGBEncoding

/**
 * Materials
 */
// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })

// Pole light material

var arcadeScreenTexture;
// const arcadeScreenTexture = new THREE.CanvasTexture(pixiCanvas);
// arcadeScreenTexture.minFilter = THREE.NearestFilter
// arcadeScreenTexture.magFilter = THREE.NearestFilter
// arcadeScreenTexture.encoding = THREE.sRGBEncoding

// const arcadeScreenMaterial = new THREE.MeshBasicMaterial({ color: 0x88ffe5, map: arcadeScreenTexture, side: THREE.DoubleSide })
var arcadeScreenMaterial = new THREE.MeshBasicMaterial({ color: 0x0, side: THREE.DoubleSide })

const paintingMaterial = new THREE.MeshBasicMaterial({ map: paintingTexture })
const aiguilleMaterial = new THREE.MeshBasicMaterial({ color: 0x0 })

// Portal light material
// const portalLightMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff })

// const floorGeometry = new THREE.PlaneGeometry(20, 20, 10, 10)
// const floorMaterial = new THREE.MeshPhongMaterial({ color: 0x57bad8 })
// const floorMesh = new THREE.Mesh(floorGeometry, floorMaterial)
// const wallMesh1 = floorMesh.clone()
// const wallMesh2 = floorMesh.clone()

// floorMesh.receiveShadow = true
// floorMesh.rotateX(-90 * Math.PI / 180)
// scene.add(floorMesh)

// wallMesh1.position.set(0, 10, -10)
// scene.add(wallMesh1)

// wallMesh2.position.set(10, 10, 0)
// wallMesh2.rotateY(-90 * Math.PI / 180)
// scene.add(wallMesh2)

/**
 * Models
 */
// const cylinderGeometry = new THREE.CylinderGeometry(1, 1, 5, 5, 5)
// const cylinderMaterial = new THREE.MeshPhongMaterial({ color: 0xFF0000 })
// const cylinderMesh = new THREE.Mesh(cylinderGeometry, cylinderMaterial)
// cylinderMesh.position.set(1, 0, 1)
// cylinderMesh.castShadow = true
// scene.add(cylinderMesh)

/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.3)
directionalLight.position.set(0.25, 0.25, 1)
directionalLight.intensity = 1;
// directionalLight.castShadow = true;
directionalLight.shadow.mapSize.width = 512; // default
directionalLight.shadow.mapSize.height = 512; // default
directionalLight.shadow.camera.near = 0.5; // default
directionalLight.shadow.camera.far = 500; // default

scene.add(directionalLight)
// const helper = new THREE.DirectionalLightHelper(directionalLight, 5);
// scene.add(helper);


//Equals
const ambientLight = new THREE.AmbientLight()
ambientLight.color = new THREE.Color(0xffffff)
ambientLight.intensity = 0.5
scene.add(ambientLight)

var idleAction, walkAction, typingAction
var mixer

var largeAiguilleMesh
var smallAiguilleMesh
var arcadeScreenMesh
var paintingMesh
var arcadeMesh
var notebookMesh

//Camera
var cameraMain
var cameraCharacter
var cameraPainting
var cameraArcade

const FindMesh = (gltf, meshName) => {
    return gltf.scene.children.find((child) => child.name === meshName)
}

gltfLoader.load(
    'models/papanouel_space_v2_1.glb',
    // 'models/papanouel_space_7.glb',
    // 'models/papanouel_space_6.glb',
    // 'models/papanouel_space_2.glb',
    // 'models/papanouel_world_2.glb',

    (gltf) => {
        scene.add(gltf.scene)

        arcadeMesh = FindMesh(gltf, 'arcade')
        arcadeMesh.material = bakedMaterial

        notebookMesh = FindMesh(gltf, 'notebook')
        notebookMesh.material = bakedMaterial
        // points[1].object = notebookMesh;

        const bakedMesh = FindMesh(gltf, 'merged')
        bakedMesh.material = bakedMaterial

        arcadeScreenMesh = FindMesh(gltf, 'arcade_screen')
        arcadeScreenMesh.material = arcadeScreenMaterial
        arcadeScreenMesh.scale.z = -1

        paintingMesh = FindMesh(gltf, 'painting_frog')
        paintingMesh.material = paintingMaterial

        largeAiguilleMesh = FindMesh(gltf, 'large_aiguille')
        largeAiguilleMesh.material = aiguilleMaterial

        smallAiguilleMesh = FindMesh(gltf, 'small_aiguille')
        smallAiguilleMesh.material = aiguilleMaterial

        //The camera imported which are tracking object (constraint) have their Visual transformation applied.
        cameraCharacter = gltf.cameras[0] //FindMesh(gltf, 'camera_papanouel')
        cameraPainting = gltf.cameras[1] //FindMesh(gltf, 'camera_painting')
        cameraArcade = gltf.cameras[2] //FindMesh(gltf, 'camera_painting')

        console.log(cameraPainting);


        /*
        gltf.cameras.forEach(element => {
            let test = new THREE.CameraHelper(element)
            scene.add(test)
        });
        */

        // updateClock()

        // camera.position.set(-10, 7, 10)
        camera.position.set(-5, 4, 5)
        controls = new OrbitControls(camera, canvas)
        controls.target.set(0, 1, 0)
        controls.update();

        cameraMain = {}
        cameraMain.quaternion = camera.quaternion.clone()
        cameraMain.position = camera.position.clone()


        // console.log(cameraMain)

        // controls.target.set(0, 1, 0)
        controls.enableZoom = false;
        controls.enablePan = false;
        controls.enableDamping = true
        controls.minPolarAngle = - Math.PI
        controls.maxPolarAngle = Math.PI / 2

        controls.minAzimuthAngle = -Math.PI / 2; // radians
        controls.maxAzimuthAngle = 0; // radians

        scene.add(camera)
    }
)

var papanouel;
var papanouelBox;

gltfLoader.load(
    'models/papanouel.glb',
    (gltf) => {
        scene.add(gltf.scene)
        papanouel = gltf.scene.getObjectByName("rig", true);
        // papanouel.position.set(1.6, 0.06, 1.4)
        // papanouel.rotateY(-Math.PI / 2)
        // points[0].object = papanouel.getObjectByName("papanouel", true);

        // papanouel.position.set(-0.17, 0.239, 1.35)
        papanouel.position.set(0, 0.23, -0.97);
        // papanouel.rotateY(Math.PI / 2)

        papanouel.scale.set(0.8, 0.8, 0.8)

        mixer = new THREE.AnimationMixer(gltf.scene);
        mixer.timeScale = 0.5;

        // idleAction = mixer.clipAction(gltf.animations.find((clip) => clip.name === 'idle'))
        // walkAction = mixer.clipAction(gltf.animations.find((clip) => clip.name === 'walk'))
        typingAction = mixer.clipAction(gltf.animations.find((clip) => clip.name === 'typing'))


        // starts idle animation
        // idleAction.play()
        typingAction.play()
        // walkAction.play()

        // startOrientation = camera.quaternion.clone()
        // camera.lookAt(papanouel.position)
        // targetOrientation = camera.quaternion.clone()
        // camera.lookAt(0, 1, 0)



    }
)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const mouse = new THREE.Vector2();

window.addEventListener('mousemove', (event) => {

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
})

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()



    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

var hoveringObjectIndex = -1

const checkMouseHover = () => {

    // update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(scene.children, true);

    if (intersects.length > 0) {

        for (let i = 0; i < points.length; i++) {
            if (intersects[0].object == points[i].object) {

                if (hoveringObjectIndex == i)
                    return
                else if (hoveringObjectIndex != -1)
                    hideObjectIcon()

                showObjectIcon(i)
                return
            }
        }

        hideObjectIcon()
    }
    else if (hoveringObjectIndex != -1) {
        hideObjectIcon()
    }
}

const showObjectIcon = (index) => {

    hoveringObjectIndex = index
    points[hoveringObjectIndex].element.classList.add('visible')
}

const hideObjectIcon = () => {

    if (hoveringObjectIndex != -1) {
        points[hoveringObjectIndex].element.classList.remove('visible')
        hoveringObjectIndex = -1;
    }
}

/**
 * Camera
 */
// Base camera
// const camera = new THREE.PerspectiveCamera(24, sizes.width / sizes.height, 0.1, 100)
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
// camera.position.set(13.6622, 14.62, 13.9703)
// camera.position.set(-4.571763330653777, 4.164744551861066, 4.762140629889497)
var controls = null

// camera.lookAt(0, 1, 0)


// Controls



// controls.dampingFactor = 0.3

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
// renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

renderer.outputEncoding = THREE.sRGBEncoding
// renderer.setClearColor(0x57BAD8, 1);
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

function calcTime(offset) {
    // create Date object for current location
    var d = new Date();

    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + (3600000 * offset));

    // return time as a string
    return nd;
}

var startOrientation
var targetOrientation

var look = new THREE.Vector3(0, 1, 0);

//w= 0.773057
//x= 0.519372
//y= -0.302303
//z= -0.203099


let pointsRoot = document.querySelector("div.points")

for (const point of points) {

    let pointDiv = document.createElement("div")
    let pointA = document.createElement("a")

    pointDiv.classList.add("point")
    pointDiv.appendChild(pointA)
    pointA.classList.add("label")
    pointA.href = "#" + point.name

    pointsRoot.appendChild(pointDiv)
    point.element = pointDiv

    pointA.addEventListener('click', (e) => {

        e.preventDefault();

        if (point.actionFunc != null)
            point.actionFunc()

    }, false)
}

function updateClock() {

    let min = calcTime('+9').getMinutes()
    let hourFix = min / 60
    min = min * 360 / 60

    let hour = (calcTime('+9').getHours() % 12) + hourFix
    hour = hour * 360 / 12

    smallAiguilleMesh.rotation.z = (180 - hour) * Math.PI / 180
    largeAiguilleMesh.rotation.z = (180 - min) * Math.PI / 180
}


try {
    (function () {
        function onTidioChatApiReady() {

            // Code after chat loaded
            // if (!sceneReady) {
            tidioChatApi.display(false);
            // console.log("tidio is ready, but not assets")
            // }

        }

        if (window.tidioChatApi) {
            window.tidioChatApi.on("ready", onTidioChatApiReady);
        } else {
            document.addEventListener("tidioChat-ready", onTidioChatApiReady);
        }
    })();
}
catch (e) {

}

/**
 * Hide points
 */

const disableInteractions = () => {

    for (const point of points) {
        hidePoint(point)
    }

    actionBarElement.classList.remove('visible');

    try {
        if (tidioChatApi != null)
            tidioChatApi.display(false);
    }
    catch (e) {

    }

    controls.enabled = false;


}

const enableInteractions = () => {

    actionBarElement.classList.add('visible');
    try {
        if (tidioChatApi != null)
            tidioChatApi.display(true);
    } catch (e) { }

    controls.enabled = true;
}

const hidePoint = (point) => {

    if (point.element.classList.contains('visible')) {
        point.element.classList.remove('pulse')
        point.element.classList.remove('visible')
    }
}

const showPoint = (point) => {

    // point.element.classList.remove('hidden')

    if (!point.element.classList.contains('visible')) {
        point.element.classList.add('visible')

        window.setTimeout(() => {
            point.element.classList.add('pulse')
        }, 1000)
    }
}

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
let frame = 0;

const tick = () => {
    // stats.begin()

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update controls
    if (controls != null && controls.enabled)
        controls.update()


    // Go through each point

    if (sceneReady && !dialogEnabled) {

        // checkMouseHover()


        // if (hoveringObjectIndex != -1) {

        //     let point = points[hoveringObjectIndex]

        //     const screenPosition = point.position.clone()
        //     screenPosition.project(camera)

        //     const translateX = (screenPosition.x * sizes.width * 0.5) - 10
        //     const translateY = (- screenPosition.y * sizes.height * 0.5) - 10
        //     point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
        // }

        for (const point of points) {
            const screenPosition = point.position.clone()
            screenPosition.project(camera)

            if (frame >= 120 || frame == 0) {
                frame = 0;
                raycaster.setFromCamera(screenPosition, camera)
                const intersects = raycaster.intersectObjects(scene.children, true)

                if (intersects.length === 0) {
                    // point.element.classList.add('visible')
                    showPoint(point)
                }
                else {
                    const intersectionDistance = intersects[0].distance
                    const pointDistance = point.position.distanceTo(camera.position)

                    if (intersectionDistance < pointDistance) {
                        hidePoint(point)
                    }
                    else {
                        // point.element.classList.add('visible')
                        showPoint(point)
                    }
                }
            }

            const translateX = (screenPosition.x * sizes.width * 0.5) - 8
            const translateY = (- screenPosition.y * sizes.height * 0.5) - 8
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`

        }

        updateClock()

        frame++;
    }


    if (mixer) {
        mixer.update(deltaTime)
    }

    // Render
    if (arcadeScreenTexture)
        arcadeScreenTexture.needsUpdate = true;
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    // stats.end()
}

console.log(renderer.info)
tick()
